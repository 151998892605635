import { createSignal, For, Match, Show, Switch } from 'solid-js';
import { Button, Dialog, DialogContent, DialogTrigger, Picture } from '@troon/ui';
import { IconInfo } from '@troon/icons/info';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { useUser } from '../providers/user';
import { getConfigValue } from '../modules/config';
import type { JSX } from 'solid-js';

export function TeeTimeSearchExplainer() {
	const user = useUser();
	const [open, setOpen] = createSignal(false);
	const [step, setStep] = createSignal(0);

	return (
		<Show when={user()?.activeTroonCardSubscription}>
			{(sub) => (
				<div class="flex flex-wrap items-center justify-between text-sm font-medium @container">
					<p>
						Your <b>{sub().name}</b> rates and course availability will be automatically applied when searching &
						booking tee times.
					</p>
					<Dialog key="tee-time-search-explainer" open={open()} onOpenChange={setOpen}>
						<DialogTrigger
							appearance="transparent"
							size="sm"
							class="-ms-3 size-fit shrink grow-0 py-1 font-medium normal-case @xl:ms-0"
						>
							Learn More <IconInfo />
						</DialogTrigger>
						<DialogContent header="New Booking Experience" headerLevel="h3">
							<div class="flex flex-col gap-4">
								<div class="relative flex aspect-video items-center justify-center">
									<Picture
										src={`${getConfigValue('IMAGE_HOST')}/web/photo/explainers/${content[step()]!.image}`}
										mode="contain"
										crop="center"
										class="size-full"
										width={588}
										height={344}
										sizes="(min-width: 768px) 36rem, 95vw"
									/>
								</div>

								<h3 class="text-center text-2xl font-semibold">{content[step()]!.title}</h3>
								<div class="text-center">{content[step()]!.content()}</div>

								<ul aria-label="Welcome screens" class="flex flex-row justify-center gap-2">
									<For each={content}>
										{(item, index) => (
											<li
												aria-current={index() === step()}
												class="text-neutral-200 transition-colors duration-200 aria-current:text-brand"
											>
												<button class="group size-8 outline-none" onClick={() => setStep(index())}>
													<span class="block h-1 rounded bg-current group-hover:bg-brand-300" />
													<span class="sr-only">{content[index()]!.title}</span>
												</button>
											</li>
										)}
									</For>
								</ul>

								<div class="flex flex-col gap-2">
									<div class="flex flex-row flex-wrap gap-2">
										<Show when={step() !== 0}>
											<Button appearance="secondary" class="shrink grow-0" onClick={() => setStep((s) => s - 1)}>
												Back
											</Button>
										</Show>
										<Switch>
											<Match when={step() !== content.length - 1}>
												<Button appearance="primary" type="button" class="grow" onClick={() => setStep((s) => s + 1)}>
													Continue <IconArrowRightMd />
												</Button>
											</Match>
											<Match when>
												<Button appearance="primary" class="grow" onClick={() => setOpen(false)}>
													Done
												</Button>
											</Match>
										</Switch>
									</div>
								</div>
							</div>
						</DialogContent>
					</Dialog>
				</div>
			)}
		</Show>
	);
}

const content: Array<{
	title: string;
	content: () => JSX.Element;
	image: string;
}> = [
	{
		title: 'Tee Time Search',
		image: 'new-experience-0-access.png',
		content: () => (
			<p>
				When you search by location, you can compare tee time availability and rates across multiple courses.{' '}
				<b>With a Troon Access membership, courses will be filtered automatically.</b>
			</p>
		),
	},
	{
		title: 'Automatic Rate Selection',
		image: 'new-experience-1-access.png',
		content: () => (
			<p>
				<b>With a Troon Access membership</b>, your discounted rate will be automatically selected when checking out.
			</p>
		),
	},
	{
		title: 'Earn Troon Rewards',
		image: 'new-experience-2-access.png',
		content: () => (
			<p>
				If the course supports Troon Rewards, you’ll see the amount of points you’ll earn when confirming your tee time.
				Points will be added to your account after checking in at the pro shop and completing your payment.
			</p>
		),
	},
];
