import { twJoin } from '@troon/tailwind-preset/merge';
import { Button, Field, Input, Label, Option, Select, TextField } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { For, Show } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { z } from 'zod';
import { createStore, produce } from 'solid-js/store';
import { IconCalendar } from '@troon/icons/calendar';
import { IconCaretDownMd } from '@troon/icons/caret-down-md';
import { IconClock } from '@troon/icons/clock';
import { IconUsers } from '@troon/icons/users';
import { timeframes } from '../modules/date-formatting';
import { coerceDate, toSearchSchema, zodGetDefaults } from '../modules/search-store';
import { SearchLocations } from './search-locations';
import type { SearchStore, SetSearchStore } from '../modules/search-store';

const today = dayjs();
export const teeTimeSearchSchema = z.object({
	players: z.coerce.number().default(2),
	startAt: z.coerce.number().default(0),
	endAt: z.coerce.number().default(24),
	regionId: z.string().nullish(),
	lat: z.coerce.number().nullish(),
	lon: z.coerce.number().nullish(),
	date: coerceDate((today.hour() < 16 ? today : today.add(1, 'day')).toDate()),
	query: z.string().nullish(),
});

type Props = {
	noButton?: boolean;
	maxDate?: Date;
	hideShowAllLink?: boolean;
};

export function TeeTimeSearch(props: Props) {
	const [store, setStore] = createStore<z.infer<typeof teeTimeSearchSchema>>(zodGetDefaults(teeTimeSearchSchema));

	const navigate = useNavigate();
	// NOTE: using a signal gets triggerd as undefined when submitting, causing the data to submit to be empty
	let formRef: HTMLFormElement;

	return (
		<form
			method="get"
			action="/tee-times"
			class="flex flex-row flex-wrap items-stretch xl:flex-nowrap"
			ref={formRef!}
			onSubmit={(e) => {
				e.preventDefault();
				e.stopPropagation();

				const query = { ...store };
				for (const [key, val] of Object.entries(query)) {
					if (typeof val === 'undefined' || val === null) {
						// @ts-ignore
						delete query[key];
					}
				}
				const params = new URLSearchParams((toSearchSchema.safeParse(query)?.data as Record<string, string>) ?? '');
				navigate(`/tee-times/?${params.toString()}`);
			}}
		>
			<TeeTimeSearchFields {...props} filters={store} setFilters={setStore} />
		</form>
	);
}

type FieldsProps = Props & {
	filters: SearchStore<typeof teeTimeSearchSchema>;
	setFilters: SetSearchStore<typeof teeTimeSearchSchema>;
	hideQueryInput?: boolean;
	onSearchUpdate?: () => void;
};

export function TeeTimeSearchFields(props: FieldsProps) {
	return (
		<>
			<Show when={!props.hideQueryInput}>
				<div
					class={twJoin(
						'shrink basis-full overflow-hidden rounded rounded-b-none border border-neutral p-2 xl:rounded-e-none xl:rounded-s-lg xl:border-b',
						props.noButton ? 'xl:basis-2/5' : 'xl:basis-2/6',
					)}
				>
					<SearchLocations
						showAllLink={!props.hideShowAllLink}
						defaultValue={props.filters.query ?? undefined}
						onSelectPlace={(place) => {
							props.setFilters(
								produce((s) => {
									s.lat = place.coordinates.latitude;
									s.lon = place.coordinates.longitude;
									s.regionId = null;
									s.query = place.name && place.regionName ? [place.name, place.regionName].join(', ') : '';
								}),
							);
							props.onSearchUpdate && props.onSearchUpdate();
						}}
						onSelectRegion={(region) => {
							props.setFilters(
								produce((s) => {
									s.lat = null;
									s.lon = null;
									s.regionId = region.id;
									s.query = region.displayValue ?? '';
								}),
							);
							props.onSearchUpdate && props.onSearchUpdate();
						}}
					/>
				</div>
			</Show>

			<TextField
				name="date"
				class={twJoin(
					'shrink grow basis-1/2 overflow-hidden border-x border-b border-neutral sm:basis-1/3 xl:border-y',
					props.noButton ? 'xl:basis-1/5' : 'xl:basis-1/6',
					props.hideQueryInput ? 'rounded-ss border-t sm:rounded-s' : 'sm:rounded-es xl:rounded-none xl:border-s-0',
				)}
			>
				<div class="h-full p-2">
					<Label class="sr-only">Date</Label>
					<Input
						type="date"
						min={dayjs().format('YYYY-MM-DD')}
						max={props.maxDate && dayjs(props.maxDate).format('YYYY-MM-DD')}
						value={dayjs(props.filters.date).format('YYYY-MM-DD')}
						class="rounded-none border-0 ps-8 focus-visible:ring-2 focus-visible:ring-brand-100"
						prefixElement={<IconCalendar class="text-brand" />}
						suffixElement={<IconCaretDownMd class="hidden text-xl md:block" />}
						onChange={(e) => {
							props.setFilters(produce((s) => (s.date = dayjs(e.currentTarget.value).toDate())));
						}}
					/>
				</div>
			</TextField>

			<Field
				class={twJoin(
					'shrink grow basis-1/2 overflow-hidden border-b border-e border-neutral sm:basis-1/3 xl:border-y',
					props.noButton ? 'xl:basis-1/5' : 'xl:basis-1/6',
					props.hideQueryInput ? 'rounded-se border-t sm:rounded-none' : '',
				)}
				name="players"
			>
				<div class="p-2">
					<Label class="sr-only">Players</Label>
					<Select
						prefixElement={<IconUsers class="text-brand" />}
						suffixElement={<IconCaretDownMd class="hidden text-xl md:block" />}
						class="rounded-none border-0 ps-8 focus-visible:ring-2 focus-visible:ring-brand-100"
						onChange={(e) => {
							props.setFilters(produce((s) => (s.players = parseInt(e.currentTarget.value, 10))));
						}}
					>
						{
							// eslint-disable-next-line solid/prefer-for
							new Array(4).fill(null).map((_, i) => (
								<Option value={i + 1} selected={props.filters.players === i + 1}>
									{i + 1} player{i > 0 ? 's' : ''}
								</Option>
							))
						}
					</Select>
				</div>
			</Field>

			<Field
				class={twJoin(
					'shrink grow basis-full overflow-hidden border-neutral sm:basis-1/3',
					props.noButton ? 'xl:basis-1/5 xl:rounded-e xl:border-r' : 'xl:basis-1/6',
					props.hideQueryInput
						? 'rounded-b border-x border-b sm:rounded-e sm:rounded-es-none sm:border-s-0 sm:border-t xl:rounded-none xl:border-e-0'
						: 'rounded-b border-x border-b sm:rounded-es-none sm:border-s-0 xl:rounded-none xl:border-y xl:border-e-0',
				)}
				name="time"
			>
				<div class="p-2">
					<Label class="sr-only">Time</Label>
					<Select
						prefixElement={<IconClock class="text-brand" />}
						class="rounded-none border-0 ps-8 focus-visible:ring-2 focus-visible:ring-brand-100"
						onChange={(e) => {
							const [startAt, endAt] = timeframes[e.currentTarget.value] ?? [0, 24];
							props.setFilters(
								produce((s) => {
									s.startAt = startAt;
									s.endAt = endAt;
								}),
							);
						}}
					>
						<For each={Object.entries(timeframes)}>
							{([label, [start, end]]) => (
								<Option selected={start === props.filters.startAt && end === props.filters.endAt}>{label}</Option>
							)}
						</For>
					</Select>
				</div>
			</Field>

			<Show when={!props.noButton}>
				<div class="flex shrink basis-full items-stretch justify-center overflow-hidden pt-4 xl:basis-auto xl:rounded-e-lg xl:border xl:border-s-0 xl:border-neutral xl:p-2">
					<Button type="submit" appearance="primary" class="w-full text-nowrap">
						Find a tee time
					</Button>
				</div>
			</Show>
		</>
	);
}
