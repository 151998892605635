import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconClock(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M12 7v5h5m-5 9a9 9 0 1 1 0-18a9 9 0 0 1 0 18"
			/>
		</Icon>
	);
}
